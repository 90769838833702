import React, { useEffect } from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import Isotope from 'isotope-layout';
import callsforspeakers from '../assets/images/portfolioImages/callsforspeakers.webp'
import xero from '../assets/images/portfolioImages/xero.webp'
import healthNutricial from '../assets/images/portfolioImages/health-nutricial.webp'
import JdTextLow from '../assets/images/portfolioImages/jd-text-low.webp'
import Dillucci from '../assets/images/portfolioImages/dillucci.webp'
import DailyGp from '../assets/images/portfolioImages/daily-gp.webp'
import ShaterShack from '../assets/images/portfolioImages/shuter-shack.webp'
import MIXAIDA from '../assets/images/portfolioImages/MIXAIDA-WERF.webp'
import SupremeLabs from '../assets/images/portfolioImages/jack-eames.webp'
import ParkPal from '../assets/images/portfolioImages/mobileApp/parkpal.webp'
import Localwala from '../assets/images/portfolioImages/mobileApp/Localwala.webp'
import Udibaba from '../assets/images/portfolioImages/mobileApp/udibaba.webp'
import Grubb from '../assets/images/portfolioImages/mobileApp/Grubb.webp'
import GrubbBolt from '../assets/images/portfolioImages/mobileApp/Grubbbolt.webp'
import GrubbMerchant from '../assets/images/portfolioImages/mobileApp/Grubbmerchant.webp'
import Eskil from '../assets/images/portfolioImages/react/reactimage-min.webp'
import ReactFablead from '../assets/images/portfolioImages/react/react-fablead.webp'

function Portfolio() {
  useEffect(() => {
    const portfolioContainer = document.querySelector('.portfolio-container');
    if (portfolioContainer) {
      const portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item'
      });

      const portfolioFilters = document.querySelectorAll('#portfolio-flters li');

      portfolioFilters.forEach((filter) => {
        filter.addEventListener('click', (e) => {
          e.preventDefault();

          portfolioFilters.forEach((el) => {
            el.classList.remove('filter-active');
          });

          filter.classList.add('filter-active');

          portfolioIsotope.arrange({
            filter: filter.getAttribute('data-filter')
          });

          portfolioIsotope.on('arrangeComplete', () => {
          });
        });
      });
    }
  }, []);
  return (
    <section id="portfolio" className="portfolio section-bg">
      <Container className="" data-aos="fade-up">
        <div className="section-title">
          <div>Our Works</div>
        </div>

        <ul id="portfolio-flters" className="text-center justify-content-center p-0" data-aos="fade-up" data-aos-delay="100">
          <li data-filter="*" className="filter-active"><span>All</span></li>
          <li data-filter=".filter-php"><span>PHP</span></li>
          <li data-filter=".filter-wordpress"><span>WORDPRESS</span></li>
          <li data-filter=".filter-squarespace"><span>SQUARESPACE</span></li>
          <li data-filter=".filter-mobile-app"><span>MOBILE APP</span></li>
          <li data-filter=".filter-react"><span>REACT JS</span></li>
        </ul>

        <Row className="portfolio-container ">
          <Col lg={4} md={12} className="portfolio-item filter-php">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={callsforspeakers} className=" portfolio-img" alt="calls for speakers" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Calls For Speakers</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>

          <Col lg={4} md={12} className="portfolio-item filter-php">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={xero} className=" portfolio-img" alt="xero" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Xero</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>

          <Col lg={4} md={12} className="portfolio-item filter-php">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={healthNutricial} className="img-fluid portfolio-img" alt="health-nutricial" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Health-nutricial</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>

          <Col lg={4} md={12} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={JdTextLow} className="portfolio-img" alt="jd-text-low" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Jd-text-low</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={Dillucci} className="portfolio-img" alt="dillucci" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Dillucci</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={DailyGp} className="portfolio-img" alt="daily-gp" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Daily-gp</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-squarespace">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={ShaterShack} className="portfolio-img" alt="shuter-shack" />
              </div>
              <div className="portfolio-info text-center">
                <h4> Shater Shack</h4>
                <p>squarespace</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-squarespace">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={MIXAIDA} className="portfolio-img" alt="Call For Speakers" />
              </div>
              <div className="portfolio-info text-center">
                <h4>MIXAIDA</h4>
                <p>Squarespace</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-squarespace">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={SupremeLabs} className="portfolio-img" alt="jack-eames" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Jack-eames</h4>
                <p>Squarespace</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={ParkPal} className="portfolio-img" alt="ParkPal" />
              </div>
              <div className="portfolio-info text-center">
                <h4>ParkPal</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Localwala} className="portfolio-img" alt="Localwala" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Localwala</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Udibaba} className="portfolio-img" alt="Udibaba" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Udibaba</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Grubb} className="portfolio-img" alt="Grubb" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Grubb</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={GrubbBolt} className="portfolio-img" alt="Grubb Bolt" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Grubb Bolt</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={GrubbMerchant} className="portfolio-img" alt="Grubb Merchant" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Grubb Merchant</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>


          <Col lg={4} className="portfolio-item filter-react">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Eskil} className="portfolio-img" alt="Eskil" />
              </div>
              <div className="portfolio-info text-center">
                <h4>eSkil</h4>
                <p>React JS</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-react">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={ReactFablead} className="portfolio-img" alt="fablead" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Fablead Developers Technolab</h4>
                <p>React JS</p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="text-center">
          <a href="/portfolio" className="btn-learn-more portifolio_btn">VIEW ALL</a>
        </div>
      </Container>
    </section>
  );
}

export default React.memo(Portfolio)
