import React, { useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Header from "../components/headers";
import Log from "../components/innerCallToLog";
import Footer from "../components/footer";
import Isotope from "isotope-layout";
import TopBar from "../components/topBar";
import callsforspeakers from '../assets/images/portfolioImages/callsforspeakers.webp'
import xero from '../assets/images/portfolioImages/xero.webp'
import healthNutricial from '../assets/images/portfolioImages/health-nutricial.webp'
import avaas from '../assets/images/portfolioImages/avaas.webp'
import ezen from '../assets/images/portfolioImages/ezen.webp'
import ballyBingos from '../assets/images/portfolioImages/bally-bingos.webp'
import econoMarketing from '../assets/images/portfolioImages/econo-marketing.webp'
import elite from '../assets/images/portfolioImages/elite-iitt.webp'
import camping from '../assets/images/portfolioImages/camping-spot.webp'
import navlexMarina from '../assets/images/portfolioImages/navlex-marina.webp'
import JdTextLow from '../assets/images/portfolioImages/jd-text-low.webp'
import dillucci from '../assets/images/portfolioImages/dillucci.webp'
import DailyGp from '../assets/images/portfolioImages/daily-gp.webp'
import Falcon from '../assets/images/portfolioImages/one-stopi.webp'
import Nuvulife from '../assets/images/portfolioImages/nuvulife.webp'
import GSR from '../assets/images/portfolioImages/gsr.webp'
import Shawn from '../assets/images/portfolioImages/shawn-furniture.webp'
import LifeBalance from '../assets/images/portfolioImages/life-balance.webp'
import LivingLondon from '../assets/images/portfolioImages/living-london.webp'
import PartnerWithYou from '../assets/images/portfolioImages/partner-with-you.webp'
import RobertDayFitness from '../assets/images/portfolioImages/robert-day-fitness.webp'
import ShaterShack from '../assets/images/portfolioImages/shuter-shack.webp'
import MIXAIDA from '../assets/images/portfolioImages/MIXAIDA-WERF.webp'
import SupremeLabs from '../assets/images/portfolioImages/jack-eames.webp'
import ParkPal from '../assets/images/portfolioImages/mobileApp/parkpal.webp'
import Localwala from '../assets/images/portfolioImages/mobileApp/Localwala.webp'
import Udibaba from '../assets/images/portfolioImages/mobileApp/udibaba.webp'
import Grubb from '../assets/images/portfolioImages/mobileApp/Grubb.webp'
import GrubbBolt from '../assets/images/portfolioImages/mobileApp/Grubbbolt.webp'
import GrubbMerchant from '../assets/images/portfolioImages/mobileApp/Grubbmerchant.webp'
import Wedding from '../assets/images/portfolioImages/mobileApp/weddingTouch.webp'
import VzyCart from '../assets/images/portfolioImages/mobileApp/VzyCart.webp'
import Altallahcrc from '../assets/images/portfolioImages/mobileApp/Altallahcrc.webp'
import Jigshow from '../assets/images/portfolioImages/mobileApp/jigshow.webp'
import Eskil from '../assets/images/portfolioImages/react/reactimage-min.webp'
import ReactFablead from '../assets/images/portfolioImages/react/react-fablead.webp'

function PortfolioPage() {
  useEffect(() => {
    const portfolioContainer = document.querySelector('.portfolio-container');
    if (portfolioContainer) {
      const portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item'
      });
      const portfolioFilters = document.querySelectorAll('#portfolio-flters li');
      portfolioFilters.forEach((filter) => {
        filter.addEventListener('click', (e) => {
          e.preventDefault();
          portfolioFilters.forEach((el) => {
            el.classList.remove('filter-active');
          });

          filter.classList.add('filter-active');

          portfolioIsotope.arrange({
            filter: filter.getAttribute('data-filter')
          });

          portfolioIsotope.on('arrangeComplete', () => {
          });
        });
      });
    }
    window.scrollTo(0, 0);

  }, []);
  return (
    <>
      <TopBar />
      <Header />
      <section id="portfolio" className="portfolio fade-up section-bg">

        <div className="section-title1">
          <div className="pt-5">Portfolio</div>
          <p className=' header-content'>We build and develop web applications & mobile applications <br /> to improve your business & take your Services to new heights.</p>
        </div>

        <ul id="portfolio-flters" className="text-center justify-content-center p-0 pt-5" data-aos="fade-up" data-aos-delay="100">
          <li data-filter="*" className="filter-active"><span>All</span></li>
          <li data-filter=".filter-php"><span>PHP</span></li>
          <li data-filter=".filter-wordpress"><span>WORDPRESS</span></li>
          <li data-filter=".filter-squarespace"><span>SQUARESPACE</span></li>
          <li data-filter=".filter-mobile-app"><span>MOBILE APP</span></li>
          <li data-filter=".filter-react"><span>REACT JS</span></li>
        </ul>

        <Row className="portfolio-container innerpageportfolio" data-aos="fade-up" data-aos-delay="200">
          <Col lg={4} className="portfolio-item filter-php text-center">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={callsforspeakers} className="portfolio-img " alt="calls for speakers" />
              </div>
              <div className="portfolio-info text-center ">
                <h4>Calls for speakers</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-php text-center">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={xero} className=" portfolio-img" alt="xero" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Xero</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className=" portfolio-item filter-php text-center">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={healthNutricial} className=" portfolio-img" alt="health-nutricial" />
              </div>
              <div className="portfolio-info text-center ">
                <h4>Health-nutricial</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-php">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={avaas} className=" portfolio-img" alt="avaas" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Avaas</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-php">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={ezen} className=" portfolio-img" alt="ezen" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Ezen</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={ballyBingos} className="img-fluid portfolio-img" alt="bally-bingos" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Bally-bingos</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className=" portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={econoMarketing} className="img-fluid" alt="econo-marketing" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Econo-marketing</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>


          <Col lg={4} className="portfolio-item filter-php">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={elite} className=" portfolio-img" alt="elite" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Elite</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-php">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={camping} className="portfolio-img" alt="camping" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Camping</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={navlexMarina} className="portfolio-img" alt="navlex-marina" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Navlex-marina </h4>
                <p>Wordpress</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={JdTextLow} className="portfolio-img" alt="jd-text-low" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Jd-text-low</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-php">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={dillucci} className="portfolio-img" alt="Dillucci" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Dillucci</h4>
                <p>PHP</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={DailyGp} className="portfolio-img" alt="daily-gp" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Daily-gp</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={Falcon} className="portfolio-img" alt="Falcon Training" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Falcon Training</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={Nuvulife} className="portfolio-img" alt="nuvulife" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Nuvulife</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={GSR} className="portfolio-img" alt="gsr" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Gsr</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={Shawn} className="portfolio-img" alt="shawn-furniture" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Shawn-furniture</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={LifeBalance} className="portfolio-img" alt="life-balance" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Life-balance</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={LivingLondon} className="portfolio-img" alt="living-london" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Living-london</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-wordpress">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={PartnerWithYou} className="portfolio-img" alt="partner-with-you" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Partner-with-you</h4>
                <p>WordPress</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-squarespace">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={RobertDayFitness} className="portfolio-img" alt="robert-day-fitness" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Robert-day-fitness</h4>
                <p>squarespace</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-squarespace">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={ShaterShack} className="portfolio-img" alt="shuter-shack" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Shuter-shack</h4>
                <p>Squarespace</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-squarespace">
            <div className="position-div">
              <div className="portfolio-img text-center">
                <Image src={MIXAIDA} className="portfolio-img" alt="MIXAIDA" />
              </div>
              <div className="portfolio-info text-center">
                <h4>MIXAIDA</h4>
                <p>Squarespace</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-squarespace">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={SupremeLabs} className="portfolio-img" alt="Supreme Labs" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Supreme Labs</h4>
                <p>Squarespace</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={ParkPal} className="portfolio-img" alt="ParkPal" />
              </div>
              <div className="portfolio-info text-center">
                <h4>ParkPal</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Localwala} className="portfolio-img" alt="Localwala" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Localwala</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Udibaba} className="portfolio-img" alt="Udibaba" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Udibaba</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Grubb} className="portfolio-img" alt="Grubb" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Grubb</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={GrubbBolt} className="portfolio-img" alt="Grubb Bolt" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Grubb Bolt</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={GrubbMerchant} className="portfolio-img" alt="Grubb Merchant" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Grubb Merchant</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={VzyCart} className="portfolio-img" alt="Vzy Cart" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Vzy Cart</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>
          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Altallahcrc} className="portfolio-img" alt="Altallahcrc" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Altallahcrc</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>

          {/* <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Jigshow} className="portfolio-img" alt="Jigsaw Royale" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Jigsaw Royale</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>*/}

          <Col lg={4} className="portfolio-item filter-mobile-app">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Wedding} className="portfolio-img" alt="Wedding Touch" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Wedding Touch</h4>
                <p>Mobile App</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-react">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={Eskil} className="portfolio-img" alt="eSkil" />
              </div>
              <div className="portfolio-info text-center">
                <h4>eSkil</h4>
                <p>React JS</p>
              </div>
            </div>
          </Col>

          <Col lg={4} className="portfolio-item filter-react">
            <div className="position-div portfolio-div">
              <div className="portfolio-img text-center">
                <Image src={ReactFablead} className="portfolio-img" alt="Fablead Developers Technolab" />
              </div>
              <div className="portfolio-info text-center">
                <h4>Fablead Developers Technolab</h4>
                <p>React JS</p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <Log />

      <Footer />
    </>
  );
}

export default React.memo(PortfolioPage)
